import * as React from "react"
import { GatsbyImage, withArtDirection, getImage } from "gatsby-plugin-image"
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import DynLink from "../components/dynlink"

require("swiper/swiper.scss")
require("swiper/components/navigation/navigation.scss")
require("swiper/components/pagination/pagination.scss")

SwiperCore.use([Navigation, Pagination, Autoplay])

export default function BannersComponent({ data }) {
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      navigation
      className="main-slider"
      pagination={{ clickable: true }}
    >
      {data.map((slide, i) => {
        const images = withArtDirection(
          getImage(slide.image.imageFile.childImageSharp.gatsbyImageData),
          [
            {
              media: "(max-width: 767px)",
              image: getImage(
                slide.image_mobile.imageFile.childImageSharp.gatsbyImageData
              ),
            },
          ]
        )
        return (
          <SwiperSlide key={i}>
            <DynLink to={slide.link} type={slide.link_type?.slug}>
              <GatsbyImage image={images} className="banner" alt="" />
            </DynLink>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}
